import React, { useMemo, useState } from 'react';
import {
  useGenerateResultsMutation,
  useGetEventResultsQuery,
} from 'graphql/types';
import { startCase, take } from 'lodash';

import Loader from 'components/Loader/Loader';
import Table from 'components/Table/Table';
import Button from 'components/Buttons/Button/Button';
import { useEvent } from 'providers/EventProvider';
import Search from 'components/Icons/Search';
import ExportButton from 'components/Buttons/ExportButton/ExportButton';
import Input from '../../../../components/Form/Input/Input';

function EventResults() {
  const { id, name } = useEvent();
  const { data, loading } = useGetEventResultsQuery({ variables: { id } });
  const [generateResults] = useGenerateResultsMutation({ variables: { id } });
  const [generateLoading, setGenerateLoading] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [filter, setFilter] = useState('');

  async function generate() {
    setGenerateLoading(true);
    await generateResults();
    alert('Results are generating, check back in a couple of minutes');
    setGenerateLoading(false);
  }

  // If results change, append hash property
  const results = useMemo(() => {
    const results = data?.results ?? [];

    return results.map((datum: any) => ({
      ...datum,
      _hash: createObjectHash(datum),
    }));
  }, [data]);

  // Get columns from the first result
  const columns = useMemo(() => {
    const columns = new Set<string>();
    const [first] = results;

    results.forEach((result: any) => {
      Object.keys(result).forEach((key) => columns.add(key));
    });

    // Filter out the _ columns, in case we want to return something and not show it
    const filtered = first
      ? Array.from(columns).filter((c) => !c.startsWith('_'))
      : [];

    return filtered.map((column) => ({
      label: startCase(column),
      key: column,
    }));
  }, [results]);

  // Filter the results by showMore or user input
  const filtered = useMemo(() => {
    const query = createHash(filter);

    if (query) {
      return results.filter((r: any) => r._hash.indexOf(query) >= 0);
    }

    return results;
  }, [filter, results]);

  if (loading) {
    return <Loader />;
  }

  if (!results) {
    return <Loader />;
  }

  // if (!results.length) {
  //   return <>No results to show</>;
  // }

  return (
    <div className="EventResults">
      <div className="EventResults-header">
        <div className="Search">
          <label>
            <Search />
          </label>
          <Input onChange={setFilter} debounce={200} placeholder="Filter" />
        </div>
        <div className="spacer" />
        <Button
          variant="secondary"
          className="Button-large"
          onClick={() => setShowMore(!showMore)}
        >
          {showMore ? 'Show less' : `Show more (${filtered.length} rows)`}
        </Button>
        <Button
          variant="secondary"
          className="Button-large"
          onClick={() => generate()}
        >
          {generateLoading ? 'Loading' : 'Regenerate'}
        </Button>
        <ExportButton
          data={filtered}
          headers={columns}
          name={`${name.toLowerCase().replace(/ /gim, '-')}-results.csv`}
        />
      </div>
      <div className="EventResults-table">
        <Table
          columns={['#', ...columns.map((c) => c.label)]}
          template={`repeat(${columns.length + 1}, minmax(min-content, auto))`}
        >
          {take(filtered, showMore ? filtered.length : 50).map(
            (data: any, index: number) => {
              return (
                <tr key={index}>
                  <td>
                    <div>{index + 1}</div>
                  </td>
                  {columns.map((column) => (
                    <td key={column.key}>
                      <div>{data[column.key]}</div>
                    </td>
                  ))}
                </tr>
              );
            }
          )}
        </Table>
      </div>
    </div>
  );
}

/**
 * Create a hash string of an object. This is pretty shit
 * but it'll do for fuzzy search atm
 * @param obj
 */
function createObjectHash(obj: any) {
  return createHash(Object.values(obj ?? {}).join(''));
}

/**
 * Create a hash string of an object. This is pretty shit
 * but it'll do for fuzzy search atm
 * @param value
 */
function createHash(value: string) {
  return value.toLowerCase().replace(/[^A-Za-z0-9]+/gim, '');
}

export default EventResults;
